import React, { useState } from 'react';
import { TextField, Grid, Button, FormHelperText, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import GeneralCheckBoxComponent from './GeneralCheckBoxComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdditionalDetailsState, AdditionalInfoComponentProps, CheckBoxHandlerParams, CustomInfo } from '../types/AdditionalDetailsTypes';

function AdditionalInfoComponent<T extends AdditionalDetailsState>(props: AdditionalInfoComponentProps<T>) {
    const { textFieldProps, checkBoxProps } = props;
    const { checkBoxHandler, ...otherCheckBoxProps } = checkBoxProps;
    const [customInfo, setCustomInfo] = useState(props.formState.customAdditionalInfo);

    const CUSTOM_INFO_LIMIT = 10;
    const KEY_MAX_LENGTH = 20;
    const VALUE_MAX_LENGTH = 200;
    const newCustomInfoItem: CustomInfo = { key: "", value: "" };

    function checkBoxInterceptor(params: CheckBoxHandlerParams) {
        const { checked } = params.event.target;

        // reset to the initial state if unchecked
        if (!checked) {
            setCustomInfo(props.formState.customAdditionalInfo);
            textFieldProps.setPageErrors(() => {
                return new Map();
            });
        } else {
            if (!customInfo.length) setCustomInfo([newCustomInfoItem]);
        }

        checkBoxHandler(params);
    }

    function updatePageErrors(action: 'add' | 'delete', fieldType, index, message = '') {
        textFieldProps.setPageErrors((prev) => {
            const newMap = new Map(prev);

            if (action === 'add') return newMap.set(`${textFieldProps.componentConfigPropKeys}-${fieldType}-${index}`, message);

            newMap.delete(`${textFieldProps.componentConfigPropKeys}-${fieldType}-${index}`);
            return newMap;
        });
    }

    function validateField(event: React.FocusEvent<HTMLInputElement>) {
        const { name } = event.target;
        const [, fieldType, index] = name.split('-');
        const { validity, validationMessage } = event.target;

        if (!validity.valid) return updatePageErrors('add', fieldType, index, textFieldProps.customErrorMessage || validationMessage);

        if (textFieldProps.pageErrors.has(`${textFieldProps.componentConfigPropKeys}-${fieldType}-${index}`)) return updatePageErrors('delete', fieldType, index);
    }

    function validateCustomInfo(customInfo: CustomInfo[]) {
        textFieldProps.setPageErrors(() => {
            return new Map();
        });

        return customInfo.map(({ key, value }, index) => {
            if (!key) updatePageErrors('add', 'key', index, textFieldProps.customErrorMessage);
            if (!value) updatePageErrors('add', 'value', index, textFieldProps.customErrorMessage);
            return textFieldProps.pageErrors;
        });
    }

    function updateAdditionalInfoStates(customInfo: CustomInfo[]) {
        textFieldProps.customInfoHandler({
            customInfo,
            componentConfigPropKeys: textFieldProps.componentConfigPropKeys
        });
    }

    function addKayValue() {
        const updatedInfo = [...customInfo, ...[newCustomInfoItem]];
        setCustomInfo(updatedInfo);
        updateAdditionalInfoStates(updatedInfo);
    }

    function removeKeyValue(index: number) {
        const updatedInfo = customInfo.filter((_, i) => i !== index);
        validateCustomInfo(updatedInfo);
        setCustomInfo(updatedInfo);
        updateAdditionalInfoStates(updatedInfo);
    }

    function handleKeyValueUpdate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { name: targetName, value } = event.target;
        const [, fieldType, index] = targetName.split('-');

        let customInfoCopy = [...customInfo];
        customInfoCopy[index] = {
            ...customInfoCopy[index],
            [fieldType]: checkValueType(value)
        }
        setCustomInfo(customInfoCopy);
        updateAdditionalInfoStates(customInfoCopy);
    }

    function checkValueType(value: string) {
        const parsedNumber = Number(value);
        const isValueString = value === '' || isNaN(parsedNumber) || (!Number.isInteger(parsedNumber));
        const isValueBoolean = isValueString && (value === 'true' || value === 'false');
        return isValueString ? (isValueBoolean ? JSON.parse(value) : value) : parsedNumber;
    }

    return (
        <Grid
            container
            direction='column'
            alignItems='flex-start'
            justifyContent='flex-start'
            sx={{ marginBottom: 2 }}>
            <Grid
                container
                direction='row'>
                <Grid item xs={8}>
                    <GeneralCheckBoxComponent {...otherCheckBoxProps} checkBoxHandler={checkBoxInterceptor} />
                </Grid>
                {checkBoxProps.isChecked &&
                    <Grid item xs={4}
                        alignItems='flex-end'
                        justifyContent='flex-end'
                        sx={{ paddingTop: 1 }}>
                        <Button
                            onClick={addKayValue}
                            disabled={customInfo.length >= CUSTOM_INFO_LIMIT}>
                            + Add key/value
                        </Button>
                    </Grid>
                }
            </Grid>
            {checkBoxProps.isChecked &&
                <TableContainer sx={{ maxWidth: 650 }} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customInfo.map(({ key, value }, index) => {
                                const renderInput = (fieldType: 'key' | 'value') => {
                                    const errorMessage = textFieldProps.pageErrors.get(`${textFieldProps.componentConfigPropKeys}-${fieldType}-${index}`);
                                    return (
                                        <>
                                            <TextField
                                                multiline={textFieldProps.multiline}
                                                required={textFieldProps.required}
                                                type="text"
                                                variant={textFieldProps.textFieldVariant || 'outlined'}
                                                id={`${textFieldProps.fieldName}-${fieldType}-${index}`}
                                                name={`${textFieldProps.componentConfigPropKeys}-${fieldType}-${index}`}
                                                value={fieldType === 'key' ? key : value}
                                                placeholder={fieldType === 'key' ? 'Key' : 'Value'}
                                                onChange={handleKeyValueUpdate}
                                                onBlur={validateField}
                                                onFocus={validateField}
                                                InputProps={{
                                                    inputProps: {
                                                        ...textFieldProps.inputProps,
                                                        maxLength: fieldType === 'key' ? KEY_MAX_LENGTH : VALUE_MAX_LENGTH
                                                    },
                                                    ...textFieldProps.adornments,
                                                }}
                                                data-testid={`${textFieldProps.dataTestId}-${fieldType}-${index}`}
                                                error={!!errorMessage}
                                            />
                                            {!!errorMessage && (
                                                <FormHelperText
                                                    sx={{ fontSize: '12px' }}
                                                    data-testid={`${textFieldProps.dataTestId}-error`}
                                                    error={!!errorMessage}>
                                                    {errorMessage}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )
                                }

                                return (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={{ width: 180 }}>
                                            {renderInput('key')}
                                        </TableCell>
                                        <TableCell sx={{ width: 300 }}>
                                            {renderInput('value')}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton aria-label="delete" size="small"
                                                onClick={() => removeKeyValue(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Grid>
    );
}

export default AdditionalInfoComponent;
