import { Fragment, useEffect, useState } from 'react';
import { Box, Typography, Grid, Link, FormHelperText } from '@mui/material';
import { getIn } from 'formik';
import { GridItems } from '../GridItems';
import { CheckboxComponent } from '../../inputComponents/CheckboxComponent';
import TooltipText from '../../inputComponents/TooltipText';
import { MultipleSelectOptions } from '../../selectComponents/MultipleSelectOptions';
import '../../../pages/AssignPromotion/AssignPromotionPage.css';
import '../GridItems.css';
import { InputNumberSpinner } from '../../inputComponents/InputNumberSpinner';
import { CurrencyTableItem } from '../../../types/componentTypes/CurrencyTypes';
import { CurrencyPaths } from '../../../constants/currency-constants';
import DisableSelectedItemsDropdown from '../../../common/DisableSelectedItemsDropdown/DisableSelectedItemsDropdown';

interface CurrenciesProps {
    formik: any;
    isEdit?: boolean;
    mainBoxClass?: string;
    contentBoxClass?: string;
}

const tooltips: { [key: string]: string } = {
    'Instant Win':
        'Once enabled, participants will need a certain amount of currency in their wallets to enter this Instant Win promotion. If the wallet has the required amount, a consumer will enter the Instant Win, while the wallet will be reduced by that amount.',
    'Collect & Get':
        'Currencies can be earned through burning codes or making some actions in the integrated experiences as part of this promotion or some other promotions. Different currencies can be associated with different prizes.',
    'Auto Collect & Get':
        'Currencies can be earned through burning codes or making some actions in the integrated experiences as part of this promotion or some other promotions. Different currencies can be associated with different prizes.',
    'Prize Draw (Lottery)':
         'Once enabled, participants will need a certain amount of currency in their wallets to enter this Instant Win promotion. If the wallet has the required amount, a consumer will enter the Prize Draw, while the wallet will be reduced by that amount.'
};

const subtitles: { [key: string]: string } = {
    'Instant Win':
        'Select a currency or multiple currencies acquired through a Collect & Get promo if you want to use them as an entry mechanism to this Instant Win promotion.',
    'Collect & Get':
        'Select the currencies that can be accumulated by participating in this promotion and/or spent on prizes from the catalog associated with this promotion.',
    'Auto Collect & Get':
        'Select the currencies that can be accumulated by participating in this promotion and/or spent on prizes from the catalog associated with this promotion.',
    'Prize Draw (Lottery)':
        'Select a currency or multiple currencies acquired through a Collect & Get promo if you want to use them as an entry mechanism to this Prize Draw promotion.',
};

function CurrencyDetails({ formik, isEdit = false, mainBoxClass, contentBoxClass }: CurrenciesProps) {
    const [filteredCurrencies, setFilteredCurrencies] = useState(['']);
    const [providedCurrencies, setProvidedCurrencies] = useState<CurrencyTableItem[]>([]);
    const [currencyList, setCurrencyList] = useState<string[]>([]);
    const [mechanic, setMechanic] = useState(formik.values.tempParams.flowOption);

    const defaultSelectedCurrs = getIn(formik.values, 'tempParams.currencyNames').filter((curr: string) => curr);
    const [selectedCurrencies, setSelectedCurrencies] = useState(defaultSelectedCurrs);
    const [currencyAllocations, setCurrencyAllocations] = useState(
        defaultSelectedCurrs.length ? { ...defaultSelectedCurrs } : {}
    );

    useEffect(() => {
        setMechanic(formik.values.tempParams.flowOption);
    }, [formik.values.tempParams.flowOption]);

    useEffect(() => {
        const currencies = formik.values.tempParams.availableCurrencies.filter(
            (currency: { country: string }) => currency.country === formik.values.configurationParameters?.country
        );
        const currenciesList = currencies.map((obj: any) => {
            return obj.name;
        });

        if (isEdit) {
            const formikCurrenciesValues = formik.values?.configurationParameters?.currencies;
            if (formikCurrenciesValues) {
                const filteredCurrencies = currencies
                    .filter((currency: { currency_id: string }) =>
                        formikCurrenciesValues.includes(currency.currency_id)
                    )
                    .map(({ name }) => name);
                setFilteredCurrencies(filteredCurrencies);
            }
        }
        setCurrencyList(currenciesList);
        setProvidedCurrencies(currencies);
    }, [
        formik.values.tempParams.availableCurrencies,
        formik.values.configurationParameters.country,
        formik.values?.configurationParameters?.currencies,
        isEdit,
    ]);

    const setFormikCurrencyFields = (value: string, index: number) => {
        let selectedCurrency: CurrencyTableItem;
        if (!value) {
            selectedCurrency = providedCurrencies.filter((curr) => curr.name === currencyAllocations[index])[0];
        } else {
            selectedCurrency = providedCurrencies.filter((curr) => curr.name === value)[0];
        }

        formik.setFieldValue(`configurationParameters.currencies[${index}]`, selectedCurrency.currency_id);
        formik.setFieldValue(`params.reduceAmount[${index}]['currencyId']`, selectedCurrency.currency_id);
    };

    const addCurrencyParameter = (props: {
        event: React.SyntheticEvent<Element, Event>;
        selectedItem: string;
        currentItemIndex: number;
    }) => {

        const {selectedItem, currentItemIndex} = props

        if (currencyAllocations[currentItemIndex]) {
            const selectedCurrenciesCopy = [...selectedCurrencies];
            const currIndex = selectedCurrencies.indexOf(currencyAllocations[currentItemIndex]);
            selectedCurrenciesCopy[currIndex] = selectedItem;
            setSelectedCurrencies(selectedCurrenciesCopy);
            formik.setFieldValue('tempParams.currencyNames', selectedCurrenciesCopy);

            const updatedCurrencyAllocation = { [currentItemIndex]: selectedItem };
            setCurrencyAllocations({ ...currencyAllocations, ...updatedCurrencyAllocation });
            setFormikCurrencyFields(selectedItem, currentItemIndex);
            return;
        }

        const allocatedCurrency = { [currentItemIndex]: selectedItem };
        setCurrencyAllocations({ ...currencyAllocations, ...allocatedCurrency });
        setSelectedCurrencies([...selectedCurrencies, selectedItem]);
        formik.setFieldValue('tempParams.currencyNames', [...selectedCurrencies, selectedItem]);

        setFormikCurrencyFields(selectedItem, currentItemIndex);
    };

    const addRow = () => {
        formik.setFieldValue('params.reduceAmount', [
            ...formik.values.params.reduceAmount,
            { currencyId: '', amount: null },
        ]);
    };

    const removeRow = (params: { index: number; currName: string }) => {
        const values = getIn(formik.values, 'configurationParameters.currencies');
        formik.setFieldValue(
            'configurationParameters.currencies',
            values.filter((_: any, i: number) => i !== params.index)
        );
        formik.setFieldValue(
            'params.reduceAmount',
            formik.values.params.reduceAmount.filter((_: any, i: number) => i !== params.index)
        );
        formik.setFieldValue(
            'tempParams.currencyNames',
            formik.values.tempParams.currencyNames.filter((_: any, i: number) => i !== params.index)
        );

        const updatedSelectedCurrencies = [...selectedCurrencies].filter((curr) => curr !== params.currName);
        setSelectedCurrencies(updatedSelectedCurrencies);
        setCurrencyAllocations({ ...updatedSelectedCurrencies });
    };

    const handleUnchecked = (event: any) => {
        const checked = event.target.checked;
        if (!checked) {
            formik.setFieldValue('params.reduceAmount', [{ currencyId: '', amount: null }]);
            formik.setFieldValue('tempParams.currencyNames', []);
            formik.setFieldValue(
                'flowLambdas',
                formik.values.flowLambdas.filter((value: string) => value !== 'currencyReducer')
            );
            formik.setFieldValue(
                'checkerLambdas',
                formik.values?.checkerLambdas?.filter((value: string) => value !== 'currencyCheckerLambda')
            );
        } else if (formik.values.checkerLambdas) {
            formik.values.checkerLambdas.push('currencyCheckerLambda')
            formik.values.flowLambdas.push('currencyReducer');
        } else if (!formik.values.checkerLambdas) {
            formik.setFieldValue(
                        'checkerLambdas',
                        ['currencyCheckerLambda']
                    );
            formik.values.flowLambdas.push('currencyReducer');
        }
    };

    const handleUncheckedValidity = (event: any) => {
        const checked = event.target.checked;
        if (!checked) {
            formik.setFieldValue('configurationParameters.validity', {});
            formik.setFieldValue('tempParams.validityDays', 1);
        }
    };

    const addCurrencyIds = (values: string[]) => {
        const ids: string[] = [];
        values.forEach((v: string) => {
            const id = providedCurrencies
                .filter((currency: { name: string }) => currency.name === v)
                .map(({ currency_id }: any) => currency_id);
            ids.push(id[0]);
        });
        formik.setFieldValue('configurationParameters.currencies', ids);
    };

    return (
        <Box className={mainBoxClass || 'text-box-currencies'}>
            <Box className={`currencies-titles`}>
                <Typography variant='body1'>{subtitles[mechanic]}</Typography>
                <TooltipText
                    text=''
                    textVariant='body1'
                    tooltip={tooltips[mechanic]}
                    styleName={isEdit ? `currencies-tooltip-edit-${mechanic.replace(/ /g, '')}` : `currencies-tooltip`}
                />
            </Box>
            {(mechanic === 'Collect & Get' || mechanic === 'Auto Collect & Get') ? (
                <Box className={contentBoxClass || 'functionality-items'}>
                    <MultipleSelectOptions
                        formik={formik}
                        preSelectedValue={isEdit && filteredCurrencies}
                        listOfOptions={currencyList}
                        formikFieldName={'tempParams.currencyNames'}
                        inputLabel='Select Currency'
                        enableAdditional={false}
                        customAction={addCurrencyIds}
                        formControlClass={'currency-multiselect'}
                    />
                    <Typography variant='body1' className='functionality-items'>
                        Can't find the right currency?&nbsp;
                        <Link href={CurrencyPaths.CreateCurrency}>Create a new currency</Link>
                    </Typography>
                    <CheckboxComponent
                        customBoxClass='currencies-checkbox'
                        fieldName={'tempParams.currencyValidity'}
                        label={
                            <TooltipText
                                text={'Currency Validity (Optional)'}
                                textVariant='body1'
                                tooltip='Enable this option if you want to set a period of validity for all currencies selected for this promotion. The minimum period is 1 day'
                                styleName={'validity-checkbox-tooltip'}
                            />
                        }
                        disabledCondition={
                            !formik.values.tempParams.currencyNames || formik.values.tempParams.currencyNames.length < 1
                        }
                        customAction={handleUncheckedValidity}
                    />
                    {formik.values.tempParams.currencyValidity && (
                        <InputNumberSpinner
                            fieldName={'tempParams.validityDays'}
                            label={'Select Number of Days'}
                            incrementValue={1}
                            customClass={'currency-spinner-input'}
                            inputClass={'currency-spinner-input'}
                        />
                    )}
                    {isEdit && (
                        <CheckboxComponent
                            fieldName={'tempParams.totalCurrencyAccumulated'}
                            label='Show Total Accumulated Per Currency'
                            labelVariant='body1'
                            customBoxClass='accumulated-currencies-checkbox'></CheckboxComponent>
                    )}
                </Box>
            ) : (
                <>
                    <Box className='functionality-items'>
                        <CheckboxComponent
                            customBoxClass='currencies-checkbox'
                            fieldName={'tempParams.useCurrency'}
                            label='Requires currency for participation'
                            customAction={handleUnchecked}
                        />
                    </Box>
                    {formik.values.tempParams.useCurrency && (
                        <>
                            <Typography variant='body1' className='functionality-items'>
                                Can't find the right currency?&nbsp;
                                <Link href={CurrencyPaths.CreateCurrency}>Create a new currency</Link>
                            </Typography>
                            <Grid container className='gridMainContainer'>
                                {formik.values.params.reduceAmount.map((_: string, index: number) => (
                                    <Fragment key={`prize-type-currency-selection-${index}`}>
                                        <GridItems
                                            maxFields={5}
                                            testId={`currency-details-currency-dropdown-menu-${index}`}
                                            formik={formik}
                                            key={`currency-details-grid-item-${index}`}
                                            index={index}
                                            component={
                                                <DisableSelectedItemsDropdown
                                                    inputComponentProps={{
                                                        uniqueKey: `currency-details-currency-input-${index}`,
                                                        label: 'Cost',
                                                        value: formik.values.params.reduceAmount[index].amount || null,
                                                        formikLabel: `params.reduceAmount[${index}]['amount']`,
                                                        errorPath: `params.reduceAmount[${index}]['amount']`,
                                                        type: 'number'
                                                    }}
                                                    dropDownProps={{
                                                        currentItemIndex: index,
                                                        label: 'Currency Name',
                                                        placeholder: 'Select A Currency',
                                                        testId: `prize-type-currency-dropdown-option-${index}`,
                                                        uniqueKey: `currency-details-currency-dropdown-${index}`,
                                                        value: currencyAllocations[index] || null,
                                                        optionsList: currencyList,
                                                        selectedItems: selectedCurrencies,
                                                        textFieldStyles: { background: 'white' },
                                                        onChangeHandler: addCurrencyParameter,
                                                    }}
                                                    formik={formik}
                                                />
                                            }
                                            addAction={addRow}
                                            removeAction={removeRow}
                                            formikValueArray='params.reduceAmount'
                                            itemClass='currency-details'
                                            customParam={{ currName: currencyAllocations[index] }}
                                        />
                                    </Fragment>
                                ))}
                                <FormHelperText error={true}>
                                    {getIn(formik.touched, 'params.reduceAmount') &&
                                        getIn(formik.errors, 'params.reduceAmount')}
                                </FormHelperText>
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Box>
    );
}

export { CurrencyDetails };
